import React from "react";
import clsx from "clsx";
import { Container } from "@atoms";
import { Share } from "@molecules";

const ShareContainer = ({ share, center }) => {
  return (
    <Container
      variant="xs"
      className={clsx("flex", { "justify-center": center })}
    >
      <Share {...share} />
    </Container>
  );
};

ShareContainer.defaultProps = {};

export default ShareContainer;
